@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');


.disable-scroll {
	overflow-y: hidden;
	scrollbar-gutter: stable;
}

.left-shadow {
	box-shadow: -0.5vw 0.5vw 0px #490105;
	margin-left: 0.25vw;
	margin-bottom: 0.5vw;
}

.right-shadow {
	box-shadow: 0.5vw 0.5vw 0px #316f9f, 1vw 1vw 0px #843a3a;
	margin-right: 1vw;
	transform: translateX(-0.5vw);
	margin-bottom: 1vw;
}

.left-shadow-mobile {
	box-shadow: -1vw 1vw 0px #490105;
	margin-left: 0.5vw;
	margin-bottom: 1vw;
}

.right-shadow-mobile {
	box-shadow: 1vw 1vw 0px #316f9f, 2vw 2vw 0px #843a3a;
	margin-right: 2vw;
	transform: translateX(-1vw);
	margin-bottom: 2vw;
}

.left-double-shadow {
	box-shadow: -1vw 1vw 0px #316f9f, -2vw 2vw 0px #843a3a;
	margin-left: 2vw;
	transform: translateX(-0.5vw);
	margin-bottom: 2vw;
}

.no-shadow {
	box-shadow: none;
}

.center {
	text-align: center;
	justify-content: center;
}

.tan-bg {
	background: #fffef2;
}

.black-bg {
	background: #232323;
}

.red-bg {
	background: #843a3a;
}

.white-bg {
	background: white;
}

.blue-bg {
	background: #316f9f;
}

.img-bg {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../public/images/bg_image.png');
	opacity: '50%';
	background-size: 100% auto;
	background-position: center top;
	background-repeat: repeat-yb;
	background-attachment: local;
}


/* LOADING ANIMATION */
.vinyl-loader {
	width: 160px;
	height: 155px;
	border-radius: 50%;
	animation: spin 1s linear infinite;
	box-shadow: none;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


/* SPLIT SCREEN FOR DESKTOP */
.container {
	display: flex;
	height: 100%;
}

.left-panel {
	flex: 1;
	overflow-y: auto;
	/* Enable vertical scrolling */
	padding: 20px;
	box-sizing: border-box;
	max-width: 60vw;
	width: 400px;
	max-height: 100%;
}

.right-panel {
	flex: 0 0 300px;
	/* Set a fixed width for the right panel */
	padding: 20px;
	box-sizing: border-box;
	background-size: cover;
	background-position: center;
	min-width: 40vw;
	width: 600px;

	display: flex;
	justify-content: center;
	align-items: center;
}



.bkgd-panel {
	padding: 30px;
	margin-bottom: 20px;
	background-color: #fffef2;
	border-radius: 30px;
	color: black;
}


/* HEADER AND FOOTER */
.banner img {
	margin: 0px;
	box-shadow: none;
}

.navbar-left img {
	box-shadow: none;
	height: 50px;
	border-radius: 50px;
	transition: 0.25s;
}

.more-header {
	display: block;
	align-items: center;
	font-size: 24px;
	font-weight: 100;
}

.more-header Button,
.more-header a,
.more-header .elem {
	color: white;
	padding: 6px 16px;
	text-decoration: none;
	font-weight: bold;
	font-size: 16px;
}

.more-header a:hover {
	background-color: #9c6161;
	cursor: pointer;
}

footer {
	display: block;
	position: absolute;
	bottom: 0;
	padding-top: 5px;
	padding-bottom: 0px;
	background-color: #17150f;
	color: #fffef2;
	font-weight: bold;
	width: 100%;
}

.App {
	min-height: 100vh;
	width: 100%;
	position: absolute;
}

.pages {
	position: relative;
	/* height: 100vh; */
	width: 100%;
	/* margin-bottom: auto; */
	/* margin-bottom: 75px; */
}

.bottom-page img {
	box-shadow: none;
	margin: 0px;
	transition: 0.25s;
}

.bottom-page img:hover {
	transform: scale(1.1);
}

.bottom-page span {
	margin-left: 20px;
}

.more-footer {
	display: flex;

	float: right;
	align-items: center;
}

.more-footer img {
	height: 30px;
	transition: 0s;
}

.more-footer img:hover {
	transform: none;
}

.more-footer span {
	margin-left: 0px;
	margin-right: 30px;
}

.newsletter-button {
	background-color: transparent;
	border: transparent;
}

.newsletter-button:hover {
	opacity: 75%;
	border: transparent;
}

.newsletter-form {
	height: auto;
	width: auto;
	justify-self: center;
}

.newsletter-footer {
	display: inline-block;
	position: relative;
}

.newsletter-footer .newsletter-white {
	opacity: 1;
	transition: opacity 0.5s;
}

.newsletter-footer .newsletter-top {
	opacity: 0;
	position: absolute;
	transition: opacity 0.5s;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	object-fit: contain;
}

.newsletter-footer:hover .newsletter-white {
	opacity: 0;
}

.newsletter-footer:hover .newsletter-top {
	opacity: 1;
}

.instagram-footer {
	display: block;
	position: relative;
}

.instagram-footer .instagram-white {
	opacity: 1;
	transition: opacity 0.5s;
}

.instagram-footer .instagram-top {
	opacity: 0;
	position: absolute;
	transition: opacity 0.5s;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	object-fit: contain;
}

.instagram-footer:hover .instagram-white {
	opacity: 0;
}

.instagram-footer:hover .instagram-top {
	opacity: 1;
}

.discord-footer {
	display: inline-block;
	position: relative;
}

.discord-footer .discord-white {
	opacity: 1;
	transition: opacity 0.5s;
}

.discord-footer .discord-top {
	opacity: 0;
	position: absolute;
	transition: opacity 0.5s;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	object-fit: contain;
}

.discord-footer:hover .discord-white {
	opacity: 0;
}

.discord-footer:hover .discord-top {
	opacity: 1;
}

.spotify-footer {
	display: block;
	position: relative;
}

.spotify-footer .spotify-white {
	opacity: 1;
	transition: opacity 0.5s;
}

.spotify-footer .spotify-top {
	opacity: 0;
	position: absolute;
	transition: opacity 0.5s;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	object-fit: contain;
}

.spotify-footer:hover .spotify-white {
	opacity: 0;
}

.spotify-footer:hover .spotify-top {
	opacity: 1;
}


/* RATINGS PAGE */
@media only screen and (min-width: 900px) {
	.albumImg {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 1s;
		position: relative;
	}

	.albumImg img {
		height: 240px;
		width: 240px;
		object-fit: cover;
		box-shadow: -10px 10px 0px #490105;
		margin: 20px;
	}

	.albumImg:hover {
		transform: scale(1.1);
		cursor: pointer;
	}
}

.badge {
	position: absolute;
	display: inline-block;
	bottom: 0px;
	right: 0px;
}

.badge-content {
	position: relative;
	background-color: rgb(49, 111, 159);
	color: white;
	font-family: "Handlee", sans-serif;
	font-weight: bold;
	font-size: 36px;
	padding: 3px 6px;
	border-radius: 50%;
	width: 75px;
	height: 75px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 900px) {
	.albumImg img {
		width: 180px;
		height: 180px;
		margin: 20px;
	}

	.badge {
		bottom: 4px;
	}

	.badge-content {
		font-size: 22px;
		padding: 3px 6px;
		width: 50px;
		height: 50px;
	}
}

@media only screen and (max-width: 650px) {
	.albumImg img {
		height: 120px;
		width: 120px;
		box-shadow: -10px 10px 0px #490105;
	}

	.badge-content {
		font-size: 18px;
		padding: 6px 8px;
		width: 30px;
		height: 30px;
	}
}


/* CAN PROBABLY DELETE AFTER RESTYLE */
.welcome {
	margin-left: 10%;
	margin-right: 10%;
}

.home {
	margin-left: 12.5%;
	margin-right: 12.5%;
	text-align: center;
}

.ratings {
	margin-left: 5%;
	margin-right: 5%;
	text-align: center;
}

.slide img {
	width: 720px;
	height: 540px;
}

.spotifyCard:hover {
	transform: scale(1.05);
	cursor: pointer;
	transition: transform 0.25s ease-in-out;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
}

.name {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 100%;
	z-index: 2;
	opacity: 0;
	transition: opacity 0.3s;
	color: white;
}



@media only screen and (max-width: 1000px) {
	.slide img {
		width: 460px;
		height: 345px;
	}
}

@media only screen and (max-width: 666px) {
	.slide img {
		width: 320px;
		height: 240px;
	}
}

/* Responsive Design for Phones */
@media only screen and (max-width: 480px) {
	.home {
		margin-left: 5%;
		margin-right: 5%;
		text-align: center;
	}

	.home h4 {
		padding-left: 0%;
		padding-right: 0%;
	}

	.slide img {
		width: 240px;
		height: 180px;
	}

	footer {
		height: auto;
	}

	.bottom-page img {
		height: 40px;
	}

	.bottom-page span {
		margin-left: 7px;
	}

	.more-footer {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.more-footer span {
		margin-right: 7px;
	}

	.navbar-right {
		display: none;
	}
}

/* Arrows for about page */
.slick-prev:before,
.slick-next:before {
	display: none !important;
}

/* saving this grayscale option if we need it again, if possible make a separate style sheet of various week styles we want to implement */

/*
html,
body {
	height: 100%;
	margin: 0;
	filter: grayscale(0.95) !important;
	// Apply grayscale to all elements 
}


// allows for interactive elements to work under the filter
a,
button,
input,
select,
textarea {
	filter: none !important;
	z-index: 999;
}

// text and other stuff 
* {
	z-index: 1;
	// things are grayscale and interactive 
}

*/


/* This makes the website rainbow.  Use for queer/lgbtq+ week! */

/*
.img-bg {
	background-image:
		linear-gradient(180deg, rgba(223, 44, 10, 0.5) 0%, rgba(255, 237, 0, 0.5) 40%, rgba(0, 128, 38, 0.5) 60%, rgba(0, 77, 255, 0.5) 74%, rgba(117, 7, 135, 0.5) 94%, rgba(145, 72, 160, 0.5) 100%),
		url('../../public/images/bg_image.png');
}

.black-bg {
	background:
		linear-gradient(180deg,
			rgba(223, 44, 10, 0.7) 0%,
			rgba(255, 237, 0, 0.7) 40%,
			rgba(0, 128, 38, 0.7) 60%,
			rgba(0, 77, 255, 0.7) 74%,
			rgba(117, 7, 135, 0.7) 94%,
			rgba(145, 72, 160, 0.7) 100%),
		rgba(0, 0, 0, 0.4);
	background-blend-mode: overlay;
}


.badge-content {
	position: relative;
	background: linear-gradient(180deg,
			rgba(226, 39, 1, 0.6) 0%,
			rgba(250, 246, 5, 0.6) 40%,
			rgba(16, 214, 99, 0.6) 60%,
			rgba(0, 110, 255, 0.6) 74%,
			rgba(109, 7, 135, 0.6) 94%,
			rgba(160, 72, 109, 0.6) 100%),
		#a76295;
	color: white;
	font-family: "Handlee", sans-serif;
	font-weight: bold;
	font-size: 36px;
	padding: 3px 6px;
	border-radius: 50%;
	width: 75px;
	height: 75px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

*/


/*Loveless pink and black theme! (not everything included in here*/
/*
.img-bg {
	background-image:
		radial-gradient(circle, rgba(167, 1, 127, 0.7) 0%, rgba(177, 0, 63, 0.85) 100%),
		url('../../public/images/bg_image.png');
}

.black-bg {
	background:
		radial-gradient(circle, rgba(167, 1, 127, 1) 0%, rgba(177, 0, 63, 1) 100%);
	background-blend-mode: overlay;
}

.left-double-shadow {
	box-shadow: -1vw 1vw 0px #17150f, -2vw 2vw 0px #b1003f;
	margin-left: 2vw;
	transform: translateX(-0.5vw);
	margin-bottom: 2vw;
}

.red-bg {
	background-color: #17150f;
}

.blue-bg {
	background-color: #17150f;
}

.badge-content {
	position: relative;
	background:
		radial-gradient(circle, rgba(167, 1, 127, 1) 0%, rgba(177, 0, 63, 1) 100%);
	color: white;
	font-family: "Handlee", sans-serif;
	font-weight: bold;
	font-size: 36px;
	padding: 3px 6px;
	border-radius: 50%;
	width: 75px;
	height: 75px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
	*/

/*Billie Eilish HMHAS Blue theme!*/
/* .img-bg {
	background-image:
		linear-gradient(0deg, rgba(26, 28, 58, 0.8) 0%, rgba(17, 46, 189, 0.7) 35%, rgba(104, 224, 249, 0.8) 100%),
		url('../../public/images/bg_image.png');
}

.red-bg {
	background-color: #1A1C3A;
}

.badge-content {
	position: relative;
	background:
		radial-gradient(circle, rgba(26, 28, 58, 1) 0%, rgba(17, 46, 189, 1) 25%, rgba(104, 224, 249, 1) 100%);
	color: white;
	font-family: "Handlee", sans-serif;
	font-weight: bold;
	font-size: 36px;
	padding: 3px 6px;
	border-radius: 50%;
	width: 75px;
	height: 75px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
} */

.rainbowContainerMobile {
	position: absolute;
	margin-right: 50vh;
	transform: translateX(-100%);

}

.rainbowContainer {
	position: flex;

}

.rainbowRibbonSignedOut {
	right: 90px !important;
}

.rainbowRibbon {
	position: absolute;
	top: -48px;
	right: 75px;
	height: 130px;
	width: 65px;
	filter: drop-shadow(-5px 5px 5px #843a3a);
	transition: transform 300ms;
}


.rainbowRibbon:hover {
	filter: brightness(85%) drop-shadow(-5px 5px 5px #843a3a);
	transform: translateY(25px);
}

.rainbowRibbonMobileSignedOut {
	right: 120px !important;
}


.rainbowRibbonMobile {
	position: absolute;
	top: -34px;
	right: 95px;
	height: 110px;
	width: 55px;
	filter: drop-shadow(-5px 5px 5px #843a3a);
	transition: transform 325ms;
}

.rainbowRibbonMobile:hover {
	filter: brightness(85%) drop-shadow(-5px 5px 5px #843a3a);
	transform: translateY(30px);
}

#rainbowRibbonLink {
	margin-right: 10px;
}